import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-header flex-nowrap border-0 pt-9" }
const _hoisted_2 = { class: "card-title m-0" }
const _hoisted_3 = { class: "symbol symbol-45px w-45px bg-light me-5" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  href: "#",
  class: "fs-4 fw-bold text-hover-primary text-gray-600 m-0"
}
const _hoisted_6 = { class: "card-toolbar m-0" }
const _hoisted_7 = {
  type: "button",
  class: "\r\n            btn\r\n            btn-clean\r\n            btn-sm\r\n            btn-icon\r\n            btn-icon-primary\r\n            btn-active-light-primary\r\n            me-n3\r\n          ",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_8 = { class: "svg-icon svg-icon-3 svg-icon-primary" }
const _hoisted_9 = { class: "card-body d-flex flex-column px-9 pt-6 pb-8" }
const _hoisted_10 = { class: "fs-2tx fw-bolder mb-3" }
const _hoisted_11 = { class: "d-flex align-items-center flex-wrap mb-5 mt-auto fs-6" }
const _hoisted_12 = { class: "fw-bold text-gray-400" }
const _hoisted_13 = { class: "d-flex align-items-center fw-bold" }
const _hoisted_14 = { class: "badge bg-light text-gray-700 px-3 py-2 me-2" }
const _hoisted_15 = { class: "text-gray-400 fs-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown3 = _resolveComponent("Dropdown3")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card h-100", _ctx.cardClasses])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            src: _ctx.icon,
            alt: "image",
            class: "p-3"
          }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("a", _hoisted_5, _toDisplayString(_ctx.title), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("button", _hoisted_7, [
          _createElementVNode("span", _hoisted_8, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen024.svg" })
          ])
        ]),
        _createVNode(_component_Dropdown3)
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.content), 1),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("span", {
          class: _normalizeClass([`svg-icon-${_ctx.color}`, "svg-icon svg-icon-3 me-1"])
        }, [
          _createVNode(_component_inline_svg, {
            src: `media/icons/duotune/Navigation/${_ctx.arrow}.svg`
          }, null, 8, ["src"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([`text-${_ctx.color}`, "fw-bolder me-2"])
        }, _toDisplayString(_ctx.footerData), 3),
        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.footerText), 1)
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.indicatorValue), 1),
        _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.indicatorLabel), 1)
      ])
    ])
  ], 2))
}